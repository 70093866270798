import React from "react";
import {
  InstagramIcon,
  FacebookIcon,
  TikTokIcon,
  AnchorTag,
} from "../component-library";
import { ContactInfo } from "./ContactInfo";
export function Footer() {
  const anchorTagProps = {
    target: "_blank",
    className: "text-neutral-800 dark:text-neutral-200",
  };
  return (
    <footer className="w-full py-4 px-4 flex justify-center items-center flex-col gap-3 text-center border border-black rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
      <div className="container">
        <div
          className="mb-px flex justify-center items-center gap-10"
          style={{ color: "#8ea549" }}
        >
          <AnchorTag
            target={anchorTagProps.target}
            link="https://www.facebook.com/GiGiHaiRBraidinG"
            classes={anchorTagProps.className}
          >
            <FacebookIcon scale="1.5" />
          </AnchorTag>

          <AnchorTag
            target={anchorTagProps.target}
            link="https://www.instagram.com/gigihairbraiding/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
            classes={anchorTagProps.className}
          >
            <InstagramIcon scale={"2.5"} />
          </AnchorTag>

          <div className=" w-9 h-9 bg-pink-300 flex flex-col justify-center items-center mr-0 rounded-md ">
            <AnchorTag
              target={anchorTagProps.target}
              link="https://www.tiktok.com/@gigi.hair.braiding?_t=8gNxu90YHMs&_r=1"
              classes={anchorTagProps.className}
            >
              <TikTokIcon scale={"1.5"} />
            </AnchorTag>
          </div>
        </div>
      </div>
      <ContactInfo />
      <div className="w-full text-center font-light">
        © 2023 Copyright: GiGiHairBraiding
      </div>
    </footer>
  );
}
