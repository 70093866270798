import React from 'react';
import { Header } from './Header';
import { Service } from './Service';
//yellow sky green pink
/* pb-4 px-4 md:px-40 */
export function Services() {
  const serviceData1 = [
    {
      title: 'BRAIDS',
      hoverColor: 'hover:bg-sky-200',
      image: '/img/braids.png',
      bgColor: 'bg-sky-200',
    },
    {
      title: 'SEW-INS',
      hoverColor: 'hover:bg-purple-200',
      image: '/img/sewin.png',
      bgColor: 'bg-purple-200',
    },
    {
      title: 'DREAD LOCKS',
      hoverColor: 'hover:bg-pink-200',
      image: '/img/dreads.png',
      bgColor: 'bg-pink-200',
    },
  ];
  const serviceData2 = [
    {
      title: 'HAIR TREATMENTS',
      hoverColor: 'hover:bg-green-200',
      image: '/img/treatment.jpeg',
      bgColor: 'bg-green-200',
    },
    {
      title: 'PONYTAILS',
      hoverColor: 'hover:bg-pink-200 ',
      bgColor: 'bg-pink-200',
      image: '/img/ponytail.png',
    },
    {
      title: 'WASH AND BLOW DRY',
      hoverColor: 'hover:bg-purple-200',
      image: '/img/blowdry.png',
      bgColor: 'bg-purple-200',
    },
  ];

  return (
    <section className="services-image w-full shadow display flex flex-col h-auto px-4 py-4 md:px-18 md:py-4">
      <section style={{ transform: 'scaleX(-1)' }}>
        <Header />
        <section className=" w-full flex flex-col md:flex-row md:min-h-[60vh] max-h-[120rem] overflow-hidden gap-2 pb-4 px-4 md:px-40  /">
          <div className=" flex flex-1 flex-col items-center gap-4 py-4 h-auto ">
            {serviceData1.map((service) => (
              <Service
                title={service.title}
                hoverColor={service.hoverColor}
                image={service.image}
                bgColor={service.bgColor}
              />
            ))}
          </div>
          <div className="w-full flex flex-1 flex-col items-center  gap-4  md:py-4 h-auto ">
            {serviceData2.map((service) => (
              <Service
                title={service.title}
                hoverColor={service.hoverColor}
                image={service.image}
                bgColor={service.bgColor}
              />
            ))}
          </div>
        </section>
      </section>
    </section>
  );
}
