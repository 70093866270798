import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from 'react-router-dom';
import { Nav } from './components/Nav';
import { Footer } from './components/Footer';
import AppFeatures from './components/AppFeatures/AppFeatures';
import { Newdew } from './components/gigi/Newdew';
import ServicesPage from './components/Routes/Services';
import Gallery from './components/Routes/Gallery';
import MeetGigi from './components/Routes/MeetGigi';
import ContactPage from './components/Routes/Contact';

const App = () => {
  return (
    <BrowserRouter>
      <Nav />

      <Routes>
        {/* <Nav />
        <Newdew />
        <AppFeatures />
        <Footer /> */}
        <Route path="/" element={<Newdew />} />
        <Route path="/meet-gigi" element={<MeetGigi />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
