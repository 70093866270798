import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  Link,
} from 'react-router-dom';

interface LinkItemProps {
  link: string;
  text: string;
  classesLi?: string;
  classesLink?: string;
}
export function LinkItem({
  link,
  text,
  classesLi,
  classesLink,
}: LinkItemProps) {
  return (
    <>
      <li className={`list-none ${classesLi}`}>
        <Link className={` ${classesLink}`} to={link}>
          {text}
        </Link>
      </li>
    </>
  );
}
