// import React from 'react';
// import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';

// type Props = {};

// const Services = () => {
//   return (
//     <MDBRow
//       className="w-full carousel flex justify-center"
//       style={{
//         justifyContent: 'center',
//         alignItems: 'center',
//         alignSelf: 'center',
//         textAlign: 'center',
//         padding: 30,
//       }}
//     >
//       <MDBCol lg={4} md={12} className="mb-4 mb-lg-0">
//         <p style={{ fontWeight: 900, color: 'gray' }}>Sew Ins</p>
//         <img
//           src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
//           className="w-100 shadow-1-strong rounded mb-4"
//           alt="Sew Ins"
//         />

//         <p style={{ fontWeight: 900, color: 'gray' }}>Dread Locks</p>
//         <video
//           width="640"
//           height="360"
//           src="./img/dreads.mp4"
//           autoPlay={true}
//         ></video>
//       </MDBCol>

//       <MDBCol lg={4} className="mb-4 mb-lg-0 p-12">
//         <p style={{ fontWeight: 900, color: 'gray' }}>Braiding</p>
//         <img
//           src="./img/Braiding.jpg"
//           width="640"
//           height="360"
//           className="shadow-1-strong rounded mb-4"
//           alt="Braiding"
//         />
//         <img
//           src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
//           className="w-100 shadow-1-strong rounded mb-4"
//           alt="Hair Treatment"
//         />
//         <p style={{ fontWeight: 900, color: 'gray' }}>Hair Treatment</p>
//       </MDBCol>

//       <MDBCol lg={4} className="mb-4 mb-lg-0">
//         <p style={{ fontWeight: 900, color: 'gray' }}>Pony Tails</p>
//         <img
//           src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(18).webp"
//           // src="./img/ponytail.jpg"
//           className="w-100 shadow-1-strong rounded mb-4"
//           alt="Pony Tails"
//         />
//         <p style={{ fontWeight: 900, color: 'gray' }}>Wash and blow dry</p>
//         <img
//           className="w-100 shadow-1-strong rounded mb-4"
//           alt="Wash and blow dry"
//           src="./img/blowdry.jpg"
//           width="700"
//           height="360"
//         />
//       </MDBCol>
//     </MDBRow>
//   );
// };

// export default Services;
import React from 'react';
import { Services } from '../Services';

export default function ServicesPage() {
  return <Services />;
}
