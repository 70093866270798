import React from 'react';
import { Link } from 'react-router-dom';

interface BookNowProps {
  classes?: string;
}
export function BookNow({ classes }: BookNowProps) {
  return (
    <div className={classes}>
      <Link to={'/'}>Feel Beautiful</Link>
    </div>
  );
}
