// import React from 'react';
// import { FaStar } from 'react-icons/fa';

// interface Review {
//   user: string;
//   rating: number;
//   comment: string;
// }

// const ReviewComponent: React.FC = () => {
//   const reviews: Review[] = [
//     {
//       user: 'HappyCustomer42',
//       rating: 5,
//       comment:
//         "The braiding at this salon is top-notch! The stylist pays incredible attention to detail, ensuring my braids are not only stylish but comfortable. I love the personalized care they provide for my head tension and face shape. I'm a loyal customer for life!",
//     },
//     {
//       user: 'BraidQueen',
//       rating: 5,
//       comment:
//         "I've been getting my braids done by the salon owner for years, and the quality is unmatched. With over 20 years of experience, you can truly see the difference. My hair texture has improved, and I always leave with a smile on my face!",
//     },
//     {
//       user: 'GorgeousLocks',
//       rating: 5,
//       comment:
//         "I can't express how wonderful this salon is. The stylist's expertise in braiding is evident in the exceptional results. My hair always looks fabulous, and they ensure I have the right style for my face shape. The salon's warm and friendly atmosphere is a bonus!",
//     },
//     {
//       user: 'BraidEnthusiast',
//       rating: 5,
//       comment:
//         "This salon is a hidden gem! The stylist's attention to detail is impeccable. They make sure that the braids not only look fantastic but also feel comfortable. The years of experience show, and the owner's international background adds a unique touch to their craft.",
//     },
//   ];

//   return (
//     <div className="reviews-container">
//       {reviews.map((review, index) => (
//         <div className="review" key={index}>
//           <div className="user">
//             <img
//               src={`customer${index + 1}.jpg`}
//               alt={`Customer ${index + 1}`}
//             />
//             <p>{review.user}</p>
//           </div>
//           <div className="rating">
//             {/* <p>
//                <p>Rated {Array.from({ length: review.rating }).map((_, i) => (
//               <FaStar key={i} color="gold" />
//             )}</p> */}

//             <p>
//               Rated{' '}
//               {Array.from({ length: review.rating }, (_, i) => (
//                 <FaStar key={i} color="gold" />
//               ))}
//             </p>
//           </div>
//           <p className="comment">{review.comment}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ReviewComponent;

import React from 'react';
import { FaStar } from 'react-icons/fa';
import './ReviewComponent.css'; // Import your CSS file for styling

interface Review {
  user: string;
  rating: number;
  comment: string;
}

const ReviewComponent: React.FC = () => {
  const reviews: Review[] = [
    // Your review data...
    {
      user: 'Tangella',
      rating: 5,
      comment:
        "The braiding at this salon is top-notch! The stylist pays incredible attention to detail, ensuring my braids are not only stylish but comfortable. I love the personalized care they provide for my head tension and face shape. I'm a loyal customer for life!",
    },
    {
      user: 'Brandy',
      rating: 4,
      comment:
        "I've been getting my braids done by the salon owner for years, and the quality is unmatched. With over 20 years of experience, you can truly see the difference. My hair texture has improved, and I always leave with a smile on my face!",
    },
    {
      user: 'Mama Lou',
      rating: 5,
      comment:
        "I can't express how wonderful this salon is. The stylist's expertise in braiding is evident in the exceptional results. My hair always looks fabulous, and they ensure I have the right style for my face shape. The salon's warm and friendly atmosphere is a bonus!",
    },
    {
      user: 'Fatima',
      rating: 5,
      comment:
        "This salon is a hidden gem! The stylist's attention to detail is impeccable. They make sure that the braids not only look fantastic but also feel comfortable. The years of experience show, and the owner's international background adds a unique touch to their craft.",
    },
  ];

  return (
    <div
      className="reviews-container "
      style={{ justifyContent: 'center', marginBottom: 80 }}
    >
      {reviews.map((review, index) => (
        <div
          className="review justify-center"
          key={index}
          style={{ justifyContent: 'center', padding: 20 }}
        >
          <div className="user">
            <p>{review.user}</p>
          </div>
          <div className="rating">
            <p
              style={{
                flexDirection: 'row',
                display: 'inline-flex',
                // padding: 20,
              }}
            >
              {Array.from({ length: review.rating }, (_, i) => (
                <FaStar key={i} color="pink" />
              ))}
            </p>
          </div>
          <div
            className="comment-bubble"
            style={{ margin: 'auto', padding: 20 }}
          >
            <p className="comment">{review.comment}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReviewComponent;
