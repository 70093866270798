import React from "react";
import { NavMobile } from "../NavMobile";
import { Link } from "react-router-dom";
import { LinkItems } from "./LinkItems";
import { BookNow } from "./BookNow";

export function Nav() {
  return (
    <header>
      <nav
        className="
            flex 
            items-center
            justify-between
            w-full
            py-4
            md:py-0
            px-7
            md:px-10
            lg:px-18
          text-dark-grey
            font-serif
            font-black
            h-28
            bg-white
            border-b-2
          "
      >
        <NavMobile />
        <div className="h-full flex justify-center items-center w-20 lg:w-auto">
          <img
            src="./my_dew_logo.png"
            alt="new_dew_logo"
            className="mix-blend-multiply h-full hidden md:block w-auto"
          ></img>
          <Link to={"/"}>
            <h1 className="w-32 lg:w-40 font-serif font-thin text-2xl lg:text-4xl  pb-3 text-center">
              {" "}
              GiGi Braiding
            </h1>
          </Link>
        </div>

        <LinkItems />
        <BookNow classes="w-20 relative md:hidden lg:hidden  underline underline-offset-4 decoration-double decoration-pink-300 decoration-3 text-xl text-center" />
      </nav>
    </header>
  );
}
