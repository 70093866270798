import React from 'react';
import { AppStoreCard } from './AppStoreCard';
import { Footer } from '../Footer';
import { Link } from 'react-router-dom';

export const Newdew = () => {
  return (
    <div>
      <section className="bg-top bg-cover bg-no-repeat jumbotron-image bg-gray-700 bg-blend-multiply">
        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
          <h3 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
            We understand your hair needs and We take good care of it
          </h3>

          <div
            className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4"
            style={{ paddingBottom: 40 }}
          >
            <span className="block bg-yellow-200 h-3 w-full"></span>
            <span className="block bg-sky-200 h-3 w-full"></span>
            <span className="block bg-green-200 h-3 w-full"></span>
            <a
              href="#"
              className="inline-flex  items-center  font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
              style={{ marginBottom: 40 }}
            >
              Start Here
              <svg
                className="w-3.5 h-3.5 ml-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a>
            <Link to={'/contact'}>
              <h1 className="w-32 lg:w-40 font-serif font-thin text-2xl lg:text-4xl  pb-3 text-center">
                <a
                  href="#contact"
                  className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
                  style={{ backgroundColor: '#f9a8d4' }}
                >
                  GiGi<span className="text-pink-300 ml-2 ">!</span>
                </a>
              </h1>
            </Link>
            {/* <a
              href="#contact"
              className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
              style={{ backgroundColor: '#f9a8d4' }}
            >
              GiGi<span className="text-pink-300 ml-2 ">!</span>
            </a> */}
          </div>
        </div>
      </section>
      <AppStoreCard />
    </div>
  );
};
