import React from "react";
export function Header() {
  return (
    <header className="flex gap-4 w-full justify-center items-center py-4 md:px-18 md:py-4">
      <h1 className="text-violet-500 text-4xl text-center md:text-right font-black w-auto ">
        GIGI'S SERVICES
      </h1>
      <div className="w-1/6 md:w-7/12 flex flex-col gap-1 overflow-hidden">
        <span className="block bg-yellow-200 h-3 w-full"></span>
        <span className="block bg-sky-200 h-3 w-full"></span>
        <span className="block bg-green-200 h-3 w-full"></span>
      </div>
    </header>
  );
}
