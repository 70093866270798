import React from 'react';
import { BookNow } from './BookNow';
import { LinkItem } from '../component-library/Links';
export function LinkItems() {
  const linkData = [
    { link: '/services', text: 'Services' },
    { link: '/gallery', text: 'Gallery' },
    { link: '/meet-gigi', text: 'Meet Gigi' },
    { link: '/contact', text: 'Contact' },
  ];
  return (
    <section className="justify-content-end hidden w-full md:flex flex-col md:items-end md:w-auto  ">
      <BookNow classes="text-end underline underline-offset-4 decoration-double decoration-pink-300 decoration-3 text-2xl" />
      <ul className="flex justify-items-end gap-5 text-1xl lg:text-2xl">
        {linkData.map((data) => (
          <LinkItem
            key={data.link}
            link={data.link}
            text={data.text}
            classesLink={
              'py-2 block  hover:underline underline-offset-4  decoration-pink-300 decoration-3'
            }
          />
        ))}
      </ul>
    </section>
  );
}
