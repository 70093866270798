import React, { useState, useRef } from 'react';
import { LinkItems } from './LinkItems';

interface LinkItemProps {
  oepn: string;
  text: string;
  classesLi?: string;
  classesLink?: string;
}

export function NavMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="relative md:hidden lg:hidden" ref={dropdownRef}>
      <section
        id="dropdownDefaultButton"
        className="relative w-10"
        onClick={toggleDropdown} // Toggle the dropdown when the button is clicked
      >
        <img src={'/icons/hamburger.png'}></img>
      </section>
      <section
        id="dropdown"
        className={`z-10 ${
          isOpen ? 'block' : 'hidden'
        } text-3xl text-white top-16 left-[-28px] h-screen w-screen bg-black divide-y absolute flex flex-col justify-star items-center gap-10`}
        onClick={toggleDropdown}
      >
        <LinkItems />
      </section>
    </nav>
  );
}
