export const images: { styleTitle: string; img: string }[] = [
  {
    styleTitle: 'https://picsum.photos/id/1018/1000/600/',
    img: './img/gh_01.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_012.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1015/1000/600/',
    img: './img/gh_02.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_03.jpeg',
  },
  // {
  //   styleTitle: 'https://picsum.photos/id/1019/1000/600/',
  //   img: './img/gh_04.jpeg',
  // },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_042.jpeg',
  },
  // {
  //   styleTitle: 'https://picsum.photos/id/1019/1000/600/',
  //   img: './img/gh_05.jpeg',
  // },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_06.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_07.jpeg',
  },
  // {
  //   styleTitle: 'https://picsum.photos/id/1019/1000/600/',
  //   img: './img/gh_08.jpeg',
  // },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_09.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_10.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_11.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_20.jpeg',
  },

  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_30.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_40.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_50.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_60.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_70.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_71.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_72.jpeg',
  },
  {
    styleTitle: 'https://picsum.photos/id/1019/1000/600/',
    img: './img/gh_072.jpeg',
  },
  // {
  //   styleTitle: 'https://picsum.photos/id/1019/1000/600/',
  //   img: './img/gh_03.jpeg',
  // },
];
