import React from 'react';
import { LinkItem } from '../component-library/Links';
export function LinkItems() {
  const linkData = [
    { link: '/services', text: 'Services' },
    { link: '/gallery', text: 'Gallery' },
    { link: '/meet-gigi', text: 'Meet Gigi' },
    { link: '/contact', text: 'Contact' },
  ];
  return (
    <section>
      {linkData.map((data) => (
        <LinkItem
          key={data.link}
          link={data.link}
          text={data.text}
          classesLi="border-b-2  border-pink-300 w-screen text-center py-4"
          classesLink="no-underline"
        />
      ))}
    </section>
  );
}
