import React from "react";
import { Map } from "../Map";
import {
  InstagramIcon,
  TikTokIcon,
  FacebookIcon,
  AnchorTag,
} from "../component-library";
export function Contact() {
  return (
    <>
      <section className="flex mt-12 mb-12 ml-3 md:mx-32 gap-28 items-center">
        <h1 className="text-3xl md:text-5xl font-extrabold mb-14 mt-4 ">
          Contact GiGi<span className="text-pink-300 ml-2 ">!</span>
        </h1>
        <section>
          <h1 className="text-2xl md:text-4xl font-extrabold text-pink-300">
            Phone<span className="text-pink-300">:</span>
          </h1>
          <h2 className="font-bold md:text-2xl">(862) 235-0801</h2>
          <h1 className="text-2xl md:text-4xl  font-extrabold text-pink-300">
            Address<span className="text-pink-300">:</span>
          </h1>
          <h2 className="font-bold md:text-2xl">
            329 16th Ave, Irvington, NJ 07111
          </h2>
          <h1 className="text-2xl md:text-4xl  font-extrabold text-pink-300">
            Hours<span className="text-pink-300">:</span>
          </h1>
          <h2 className="font-bold md:text-2xl">Monday to Sunday</h2>
          <h2 className="font-bold md:text-2xl">9AM - 6PM</h2>
        </section>
        <section className="hidden md:flex justify-center items-center gap-28">
          <AnchorTag
            link="https://www.facebook.com/GiGiHaiRBraidinG"
            target="_blank"
            classes="text-pink-300"
          >
            <FacebookIcon scale="3.5" />
          </AnchorTag>
          <AnchorTag
            link="https://www.instagram.com/gigihairbraiding/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
            target="_blank"
            classes="text-pink-300"
          >
            <InstagramIcon scale="4.5" />
          </AnchorTag>
          <div className="w-20 h-20 bg-pink-300 flex flex-col justify-center items-center mr-0 rounded-md ">
            <AnchorTag
              link="https://www.tiktok.com/@gigi.hair.braiding?_t=8gNxu90YHMs&_r=1"
              target="_blank"
              classes="text-pink-300"
            >
              <TikTokIcon scale="3.5" />
            </AnchorTag>
          </div>
        </section>
      </section>
      <section className="w-screen h-1/4">
        <Map />
      </section>
    </>
  );
}
