import React from "react";
import GoogleMapReact from "google-map-react";

export function Map() {
  const defaultProps = {
    center: {
      lat: 40.7377741,
      lng: -74.2116696,
    },
    zoom: 11,
  };
  const renderMarkers = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: { lat: defaultProps.center.lat, lng: defaultProps.center.lng },
      map,
      /*    label: {
        fontSize: "16pt",
        fontWeight: "strong",
        fontFamily: "Montserrat",
        text: "329 16th Ave, Irvington, NJ",
      }, */
    });
    return marker;
  };

  console.log(process.env.REACT_APP_GOOGLE_API_KEY);
  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY
            ? process.env.REACT_APP_GOOGLE_API_KEY
            : "",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
      ></GoogleMapReact>
    </div>
  );
}
