import React, { SetStateAction, useState, useCallback } from 'react';
import { images } from '../Image-Gallery/ImagesObj';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './Gallery.css';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

type Props = {};

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1
    );
  }, []);
  const handlePrevious = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  }, []);
  const handleDotClick = (index: React.SetStateAction<number>) => {
    setCurrentIndex(index);
  };
  return (
    <>
      <section className="bg-top bg-cover styles-image bg-gray-700 bg-blend-multiply">
        <div
          className="slide  carousel flex justify-center"
          style={{ marginTop: 70, marginBottom: 50 }}
        >
          <h1 className="hover:bg-sky-200  text-center text-2xl md:text-4xl font-black h-15 border-2 w-1/2 flex justify-center items-center ">
            Styles
          </h1>
        </div>
        <div className="box" style={{ marginTop: 12, marginBottom: 90 }}>
          <Carousel
            axis="horizontal"
            showStatus={false}
            className="relative"
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${
                    hasPrev ? 'absolute' : 'hidden'
                  } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-70 hover:opacity-100 cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <FaArrowCircleLeft className="w-9 h-9 text-violet-500 " />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div
                  className={`${
                    hasNext ? 'absolute' : 'hidden'
                  } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-70 hover:opacity-100 cursor-pointer z-20`}
                  onClick={clickHandler}
                >
                  <FaArrowCircleRight className="w-9 h-9 text-violet-500 " />
                </div>
              );
            }}
            useKeyboardArrows={true}
            width={'70%'}
            showArrows={true}
            showIndicators={true}
            showThumbs={true}
            centerMode={true}
            transitionTime={15}
            dynamicHeight={true}
            infiniteLoop={true}
          >
            {images.map((URL, index) => (
              <div className="w-full relative">
                <img
                  alt="style_example"
                  src={URL.img}
                  key={index}
                  style={{ borderRadius: 15, objectFit: 'contain' }}
                  ////
                  className="w-1/2 h-1/2 top-0 left-0 object-cover rounded-2xl"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </>
  );
};
export default Gallery;
