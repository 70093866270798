import React from "react";
interface AnchorTagProps {
  children: React.ReactSVGElement | React.ReactElement;
  target?: string;
  link: string;
  classes?: string;
}
export function AnchorTag({ children, target, link, classes }: AnchorTagProps) {
  return (
    <a target={target} rel="noreferrer" href={link} className={classes}>
      {children}
    </a>
  );
}
