import React, { useState } from 'react';
interface Props {
  hoverColor: string;
  title: string;
  image: string;
  bgColor: string;
}
export function Service({ hoverColor, title, image, bgColor }: Props) {
  const [showDescription, setShowDescription] = useState(false);
  const descriptionStyles = showDescription ? `h-[70vh]` : '';
  const headerStyles = showDescription ? `${bgColor} border-none` : '';
  return (
    <div
      className={`w-full flex flex-col items-center overflow-hidden ${descriptionStyles}`}
      onClick={() => setShowDescription(!showDescription)}
    >
      <h1
        className={`${hoverColor} ${headerStyles} text-center text-2xl md:text-4xl font-black h-20 border-2 w-full flex justify-center items-center `}
      >
        {title}
      </h1>
      {showDescription && (
        <div className="h-80 w-80 pt-10">
          <img src={image} alt="location" />
        </div>
      )}
    </div>
  );
}
