import React from 'react';
import ReviewComponent from './Reviews';
export const AppStoreCard = () => {
  return (
    <div
      style={{ backgroundColor: 'var(--light-grey)' }}
      className="w-full p-4 text-center border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700"
    >
      <h2 className="mb-2 text-5xl font-bold text-gray-900 dark:text-white">
        Over 20 years of experience in afro textured hair
      </h2>
      <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
        Come into our shop for braiding, dreads, ponytails, crochets and more
      </p>
      <ReviewComponent />
    </div>
  );
};
