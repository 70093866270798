import React from 'react';

type Props = {};

const MeetGigi = (props: Props) => {
  return (
    <div style={{}}>
      <div
        style={{ backgroundColor: '#f9a8d4' }}
        className="mx-auto max-w-screen-xl text-center m-24 p-5"
      >
        <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
          <img
            src={'./img/elpha.jpg'}
            width={500}
            style={{ borderRadius: 150 }}
          />
          <div>
            <h1 className="mb-2 text-5xl font-bold text-gray-900 dark:text-white color:">
              <div className="flex flex-col gap-1 overflow-hidden">
                <span className="text-center">Meet Gigi</span>
                <span className="block bg-yellow-200 h-3 w-full"></span>
                <span className="block bg-sky-200 h-3 w-full"></span>
                <span className="block bg-green-200 h-3 w-full"></span>
              </div>
            </h1>

            <p className="w-full" style={{ color: '#fff', fontSize: 18 }}>
              <i style={{ fontSize: 34 }}>Welcome to Gigi Hair Braiding </i>
              where the art of braiding is not just a profession; it's a passion
              cultivated over two decades. I'm Gigi, your dedicated and licensed
              hair stylist, and I'm thrilled to be your go-to expert for all
              things braids. With years of experience in the industry, I've not
              only honed my craft but have also had the privilege of helping
              countless clients feel confident and beautiful. At Gigi Hair
              Braiding, it's not just about styling hair; it's about creating a
              warm and inviting space where you can relax, transform, and leave
              with a smile on your face. Whether you're looking for classic
              braids, trendy twists, or unique, personalized creations, I'm here
              to make your vision a reality. Thank you for choosing Gigi Hair
              Braiding, where every braid is a work of art, and every client is
              a treasured part of our braiding family.
            </p>
          </div>
        </div>
        <section className="w-full text-center py-20">
          <div className="w-full bg-neutral-300  text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200 ">
            <h1
              className="text-neutral-800 dark:text-neutral-200 text-3xl "
              style={{ color: '#fff' }}
            >
              <i>
                Come on in, and let's make your hair dreams come true, one braid
                at a time! Can't wait to meet you and work some braiding magic!
              </i>
            </h1>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MeetGigi;
